import store from "@/store.js";
export class Functions{
    setCookie(cname, cvalue ,expiry) {
        var now = new Date()
        var time = now.getTime()
        var expireTime=time + 1000*3600
        now.setTime(expireTime)
        // alert(now.toUTCString())
        // document.cookie=cname +"=" +cvalue
        // let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";expires=" + expiry  + ' ;path=/'
      }
     getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
      clearToken(name){
          document.cookie[name] = ""
      }
      validCookie(cname){
        if(this.getCookie(cname)===""){
            return false
        }else{
            return true
        }
      }
      removeCookie(name){
        // var now = new Date()
        // var time = now.getTime()
        // var expireTime=time - 1000*3600
        // now.setTime(expireTime)
        // alert(now.toUTCString())
        document.cokie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 UTC;path=/'
      }
      getFromDbPublic(path){
        //LOAD CREDENTIALS
        let response = fetch(store.store.api_base_url +path,{
          method: "GET",
      }).then(res=>res.json()).then(res=>res)
      return response
    }
      getFromDbPrivate(path){
        let response = fetch(store.store.api_base_url +path,{
          method: "GET",
          headers: {
            "Accept": "application/json",
              "Content-Type": "application/json",
              "Origin": "",
              "Authorization": "Bearer " + this.getCookie(store.store.token_name)
          }
      }).then(res=>res.json()).then(res=>res)
      if(response.status==200){
        this.setCookie(store.store.token_name,response.response.token)
        store.store.token = response.response.token
      }else if(response.status==403){
        store.store.token=""
        this.setCookie(store.store.cookie_name)
        router.push("/")
      }
      return response
    }
    postToDbPublic(path,json){
        //LOAD CREDENTIALS
        let response = fetch(store.store.api_base_url +path,{
          method: "POST",
          body:json
      }).then(res=>res.json()).then(res=>res)
      return response
    }
    postToDbPrivate(path,json){
      //LOAD CREDENTIALS
      let response = fetch(store.store.api_base_url +path,{
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Origin": "",
            "Authorization": "Bearer " + store.store.token
        },
        body:json
    }).then(res=>res.json()).then(res=>res)
    if(response.status==200){
      this.setCookie(store.store.token_name,response.response.token)
      store.store.token = response.response.token
    }else if(response.status==403){
      store.store.token=""
      this.setCookie(store.store.cookie_name)
      router.push("/")
    }
    return response
  }
  postFormDataToDbPrivate(path,formData){
    //LOAD CREDENTIALS
    console.log(formData)
    let response = fetch(store.store.api_base_url +path,{
      method: "POST",
      headers: {
          "Accept": "application/json",
          // "Content-Type": "application/json",
          "Origin": "",
          "Authorization": "Bearer " + store.store.token
      },
      body:formData
  }).then(res=>res.json()).then(res=>res)
  if(response.status==200){
    this.setCookie(store.store.token_name,response.response.token)
    store.store.token = response.response.token
  }else if(response.status==403){
    store.store.token=""
    this.setCookie(store.store.cookie_name)
    router.push("/")
  }
  return response
  }
  formatDouble(val){
    return parseFloat(val).toLocaleString("en-US",{minimumFractionDigits:2,maximumFractionDigits:2})
  }
  loadStore(response){
    store.api_base_url = ""
    store.store.token = response.token
    store.store.logged = true
    store.store.ut = response.utype_id
    store.store.uname=response.full_name
    store.store.uid = response.id
    store.store.picture= "https://sysu-app.shop/api/v1/" + response.picture
    store.store.email=response.email

  }

}