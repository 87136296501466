<template>
  <v-app>
    <v-app-bar class="bg-primary">
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer">
      <NavigationList></NavigationList>
    </v-navigation-drawer>
    <v-main class="bg-secondary">
        <router-view/>
    </v-main>
    <MyAlert :type="alert_type" :alert="alert" :msg="alert_msg" :title="alert_title"></MyAlert>
  </v-app>
</template>
<script>
import MyAlert from './mycomponents/MyAlert.vue'
import { Functions } from './utilities/Functions'
import {provide} from 'vue'
import store from '@/store.js'
import NavigationList from './mycomponents/NavigationList.vue'
export default{
  data(){
    provide("global",store)
    let fn = new Functions()
    return{
      drawer:false,
      logged:store.store.token!="",
      alert:store.store.alert,
      alert_type:store.store.alert_type,
      alert_msg:store.store.alert_msg,
      alert_title:store.store.alert_title,

      user:'',
      fn
    }
  },
  components:{NavigationList,MyAlert},
  watch:{
    async drawer(){
      if(store.store.logged){
        let response = await this.fn.getFromDbPrivate("/user_by_token")//?token=" + this.f.getCookie(this.gv.store.token_name));
            if(response.status=200){
                this.user=response.response
            }else{
                alert(JSON.stringify(this.response))
            }
      }
    }
  },
  methods:{
  },
  mounted(){
  },
  async created(){
    //RESTORE STORE DATA
    let user = await this.fn.getFromDbPrivate("/user_by_token")
      if(user.status==200){
        this.fn.loadStore(user.response);
        // store.api_base_url = ""
        // store.store.token = user.token
        // store.store.logged = true
        // store.store.ut = user.response.utype_id
        // store.store.uname=user.response.full_name
        // store.store.uid = user.response.id
        // store.store.picture= store.api_base_url + "/" + user.response.picture
        // store.store.email=user.response.email
      }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
