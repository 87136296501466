<template lang="">
    <div>
        <v-alert 
            :type="type" 
            :model-value="alert"
            :title="title"
            :text="msg"
            variant="tonal"
            closable
            transition="slide-y-transition"
            >
        </v-alert>
    </div>
</template>
<script>
export default {
    props:['type','alert','msg','title']
}
</script>
<style lang="">
    
</style>