<template lang="">
  <v-col>
    <v-row class="text-lg-h3 text-sm-h5 justify-center mt-5">Events</v-row>

  </v-col>
</template>
<script>
import { Functions } from '@/utilities/Functions';
export default {
  data(){
    const fn = new Functions()
    return{
      fn,
      main_meter:[],
      loading:false
    }
  },
  mounted(){
    this.loadData()
  },
  methods:{
    async loadData(){
    }
  }
}
</script>
<style lang="">
  
</style>