import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store.js"
import { Functions } from '@/utilities/Functions'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/developer',
    name: 'developer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Developer.vue')
  },
  {
    path:'/login',
    name:'login',
    component:()=>import("../views/Login.vue"),
    meta:{auth:false}
  },
  {
    path:'/register',
    name:'register',
    component:()=>import("../views/Register.vue")
  },
  {
    path:'/profile',
    name:'profile',
    meta:{auth:true,access:[2]},
    component:()=>import("../views/Profile.vue")
  },
  {
    path:'/bill_consumption',
    name:'bill_consumption',
    meta:{auth:true},
    component:()=>import("../views/BillConsumption.vue")
  },
  {
    path:'/user_profile',
    name:'user_profile',
    meta:{auth:true,access:[1,2]},
    component:()=>import("../views/UserProfile.vue"),
  },
  {
    path:'/diser',
    name:'diser',
    meta:{auth:true,access:[2]},
    component:()=>import("../views/users/UserHome.vue"),
    children:[
      {
        path:'dashboard',
        name:'user_dashboard',
        meta:{auth:true,access:[2]},
        component:()=>import("../views/users/UserDashboard.vue"),
      },
      {
        path:'diser_inventory_count',
        name:'diser_count',
        meta:{auth:true,access:[2]},
        component:()=>import("../views/users/DiserCount.vue"),
      },
      {
        path:'diser_inventory_count/:id',
        name:'diser_inventory_count_maintain',
        meta:{auth:true,access:[2]},
        component:()=>import("../views/InventoryCountMaintain.vue")
      },
    ]
  },
  {
    path:'/admin',
    name:'admin',
    meta:{auth:true,access:[1]},
    component:()=>import("../views/Admin1.vue"),
    children:[
      {
        path:'users',
        name:'admin_users',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/AdminUsers.vue")
      },
      {
        path:'dashboard',
        name:'admin_dashboard',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/AdminDashboard.vue")
      },
      {
        path:'materials',
        name:'materials',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/Materials.vue")
      },
      {
        path:'inventory_count/:id',
        name:'inventory_count_maintain',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/InventoryCountMaintain.vue")
      },
      {
        path:'inventory_count',
        name:'inventory_count',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/InventoryCount.vue")
      },
      {
        path:'customers',
        name:'customers',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/Customers.vue")
      },
      {
        path:'invoices',
        name:'admin_invoices',
        meta:{auth:true,access:[1]},
        component:()=>import("../views/AdminInvoices.vue")
      },
    ]
  },
]

const router = createRouter({
  // history:createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async(to,from,next)=>{
  let f = new  Functions()
  if(to.meta.auth){
    let response = await f.getFromDbPrivate("/token_status") 
    if(response.status==403){
      f.setCookie(store.store.token_name,"")
      store.store.token=""
      next('/login')
    }else{
      let user = await f.getFromDbPrivate("/user_by_token")
      if(user.status==200){
        store.store.token = user.token
        store.store.logged = true
        store.store.ut = user.response.utype_id
        store.store.uid = user.response.id
        if(typeof(to.meta.access)=="undefined"){
          //PUBLIC
          next()
        }else{
          //PRIVATE
          if(to.meta.access.includes(parseInt(store.store.ut))){
            next()
          }else{
            next('/')
          }
        }
      }else{
        next('/login')
      }
    }
  }else{
    next()
  }
})



export default router
