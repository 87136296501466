import {reactive} from 'vue'
const store = reactive({
    token:'',
    logged:false,
    api_base_url:'https://sysu-app.shop/api/v1',
    token_name:"dbi_token",
    ut:0,
    uid:0,
    uname:'',
    email:'',
    coid:0,
    picture:'',
    alert:false,
    alert_msg:'',
    alert_type:'success',
    alert_title:''
})

export default{store}