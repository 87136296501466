<template lang="">
    <v-col>
        <v-list v-if="gv.store.logged">
            <v-list-item>
                <v-card @click="profile">
                    <v-avatar size="100px" cover>
                        <v-img :src="gv.store.picture" cover></v-img>
                    </v-avatar>
                </v-card>
            </v-list-item>
            <v-list-item class="text-subtitle-2 font-weight-bold">
                {{gv.store.uname}}
            </v-list-item>
            <v-list-item class="text-subtitle-2 font-weight-bold">
                {{gv.store.email}}
            </v-list-item>
        </v-list>
        <v-divider v-if="gv.store.logged"></v-divider>
        <v-list nav>
          <v-list-item router to="/">Home</v-list-item>
          <v-list-item v-if="!gv.store.logged" router to="/login">Login</v-list-item>
          <v-list-item v-if="gv.store.ut!=1 && gv.store.logged" router to="/diser/dashboard">Dashboard</v-list-item>
          <v-list-item v-if="gv.store.ut==1" router to="/admin/dashboard">Dashboard</v-list-item>
          <v-list-item v-if="gv.store.ut==1" router to="/admin/users">Users</v-list-item>
          <v-list-item v-if="gv.store.ut==1" router to="/admin/materials">Materials</v-list-item>
          <v-list-item v-if="gv.store.ut==1" router to="/admin/customers">Customers</v-list-item>
          <v-list-item v-if="gv.store.ut==1" router to="/admin/inventory_count">Inventory Count</v-list-item>
          <v-list-item router to="/developer">Developer</v-list-item>
          <v-list-item v-if="gv.store.logged" router to="/" @click="logout">Logout</v-list-item>
        </v-list>

    </v-col>
</template>
<script>
import { inject } from 'vue';
import { Functions } from '@/utilities/Functions';
import router from '@/router';
export default {
    data(){
        let f= new Functions()
        let gv = inject("global")
        return{
            gv,
            f,
        }
    },
    methods:{
        profile(){
            router.push("/user_profile")
        },
        async load_data(){
        },
        async logout(){
            let response = await this.f.postToDbPrivate("/logout",null)
            this.gv.store.token=""
            this.gv.store.logged=false
            this.gv.store.ut=0
            this.gv.uid=0
            this.f.removeCookie(this.gv.store.token_name)
            // this.f.setCookie(this.gv.store.token_name,"")
            
            //REMOVE API TOKEN FROM REFRESH TOKEN
            
        }
    }
}
</script>
<style lang="">
    
</style>